import React from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';

import { CarouselQuotation } from '../index';
import { track } from '../../utilities/trackerStatistics';

const HomeCarouselQuotation = ({ quotationList }) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div className="home-banks-carousel">
      <h4 className="default-title text-center" dangerouslySetInnerHTML={{ __html: t('home.most_asked_mutuals') }} />
      <CarouselQuotation quotationList={quotationList} preventiviDaMostrare={3} showCardCta={false} />
      <div className="home-banks-carousel-button-container">
        <button
          type="button"
          className="home-banks-carousel-button"
          onClick={() => {
            window.dataLayer.push({
              event: 'init_flusso',
              eventProps: { from: 'cta_Lista_mutui' },
            });
            // mixpanel.track('vai al flusso', {
            //   from: 'cta_Lista_mutui',
            // });
            track('vai al flusso', {
              from: 'cta_Lista_mutui',
            });
            router.push(`/cerca-mutuo`);
          }}>
          {t('home.calculate_payment')}
        </button>
      </div>
    </div>
  );
};

export default HomeCarouselQuotation;
