import React, { useRef } from "react"
import { gsap } from "gsap"
// import { Box } from "theme-ui"
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect"


const FadeIn = ({ as, children, duration, delay, x, y, ease }) => {
  const el = useRef()

  useIsomorphicLayoutEffect(() => {
    gsap.to(el.current, {
      x: 0,
      y: 0,
      opacity: 1,
      delay: delay || 0,
      duration: duration || 2,
      ease: ease || "power4.out",
    })
  }, [])

  return (
    <div
      style={{
        opacity: 0,
        transform: "translate(" + (x || 0) + "px, " + (y || 0) + "px)",
        display: "initial",
      }}
      ref={el}
    >
      {children}
    </div>
  )
}

export default FadeIn
