import React, { useEffect } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import Image from 'next/image';

import dynamic from 'next/dynamic';
const TrustpilotReviews = dynamic(() => import('../components/TrustpilotReviews.js'), { ssr: false });
const TrustPilot = dynamic(() => import('../components/TrustPilot'), { ssr: false });

import HomeMutualInput from '../components/home/HomeMutualInput.js';
import FadeIn from '../animation/FadeIn';
import useStorage from '../hooks/useStorage.ts';
import HeaderWithMenu from '../components/headerWithMenu/HeaderWithMenu';
import { getMenuItems } from '../api/menu/menu';
import { getQuotationListForCarouselQuotation } from '../components/carouselQuotation/CarouselQuotation';
import HomeCarouselQuotation from '../components/home/HomeCarouselQuotation';

import image1 from '../public/checkboxes.png';
import image2 from '../public/results.jpg';
import image3 from '../public/omino.png';
import { track } from '../utilities/trackerStatistics.js';

const MAX_WIDTH = '1600px';

function Home(props) {
  const { menuItems = [], quotationListForCarousel } = props;
  const { getItem, getItems, setItem, removeItem } = useStorage();
  const { t } = useTranslation();

  useEffect(() => {
    //////////////////////////////////////////////////////////////
    // Se localStorage ha currentRow settato a un intervallo di google sheet ('A2:Z100')
    // allora sta utilizzando una vecchia versione del client:
    // puliamo quindi il locale storage per permetter l'inizio di una nuova sessione col corretto currentRow
    const currentRow = getItem('currentRow', 'local');
    if (typeof currentRow === 'string' && !currentRow.match(/^[0-9a-fA-F]{24}$/)) {
      Object.keys(getItems('local')).forEach(k => removeItem(k, 'local'));
    }
    //////////////////////////////////////////////////////////////

    const sessionTimestamp = getItem('sessionTimestamp', 'local');
    if (sessionTimestamp) {
      // if sessionTimestamp is older than 5 days
      if (moment(sessionTimestamp).add(5, 'days').isBefore(moment())) {
        // clear localStorage
        Object.keys(getItems('local')).forEach(k => removeItem(k, 'local'));
        // set new timestamp
        setItem('sessionTimestamp', moment().toISOString(), 'local');
      } else {
        // else refresh timestamp
        setItem('sessionTimestamp', moment().toISOString(), 'local');
      }
    } else {
      setItem('sessionTimestamp', moment().toISOString(), 'local');
    }

    setItem('landedIn', 'home', 'local');
    setItem('landingFrom', `homepage`, 'local');
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: 'from_home',
    });
  }, []);


  return (
    <>
      <Head>
        <title>{t('home.head_title')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content={t('home.head_description')} />
        <meta name="keywords" content={t('home.head_keywords')} />
      </Head>
      <FadeIn>
        <HeaderWithMenu menuItems={menuItems} />
        <div>
          <div className="home-main-banner">
            <div style={{ maxWidth: MAX_WIDTH, margin: '0 auto' }}>
              <div className="container-wider">
                <div
                  className="two-col-container-b"
                  style={{ backgroundImage: 'url(https://cms.tassomutuo.it/wp-content/uploads/2024/05/house_head.jpg)' }}>
                  <div className="two-col-b">
                    <TrustPilot inMainBanner />
                    <div className="main-banner-text">
                      <h2 className="default-title" style={{ color: '#fff' }}>
                        {t('home.main_banner_title')}
                      </h2>
                      <h3 className="default-subtitle" style={{ color: '#fff', fontWeight: 'normal' }}>
                        {t('home.main_banner_subtitle')}
                      </h3>
                    </div>
                    <HomeMutualInput  />
                  </div>
                  <div className="two-col main-banner-col-image"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="how-does-it-work">
            <div style={{ maxWidth: MAX_WIDTH, margin: '0 auto' }}>
              <div className="container-wider">
                <h4 className="default-title text-center" ><span className="color-light-green">Come funziona</span> TassoMutuo?</h4>
                <div className="three-col-container">
                  <div className="three-col">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Image
                        width={300}
                        height={289}
                        src={image1}
                        className="image-two-col"
                        alt="TassoMutuo rispondi ad alcune domande"
                        placeholder="blur"
                      />
                    </div>
                    <p>{t('home.how_does_it_work_text_1')}</p>
                  </div>
                  <div className="three-col">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Image
                        width={300}
                        height={289}
                        src={image2}
                        className="image-two-col"
                        alt="TassoMutuo confronta le proposte di mutuo"
                        placeholder="blur"
                      />
                    </div>
                    <p>{t('home.how_does_it_work_text_2')}</p>
                  </div>
                  <div className="three-col">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Image
                        width={300}
                        height={289}
                        src={image3}
                        className="image-two-col"
                        alt="TassoMutuo parla con un consulente che analizzerà la tua pratica"
                        placeholder="blur"
                      />
                    </div>
                    <p>{t('home.how_does_it_work_text_3')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HomeCarouselQuotation quotationList={quotationListForCarousel} />

          <div id="advantages">
            <div style={{ maxWidth: MAX_WIDTH, margin: '0 auto' }}>
              <div className="container-wider">
                <div className="three-col-container">
                  <div className="three-col-single">
                    <h4 className="default-title" dangerouslySetInnerHTML={{ __html: t('home.advantages_title') }} />
                  </div>
                  <div className="three-col-double">
                    <div className="text-with-dots">
                      <p>{t('home.advantages_text_1')}</p>
                      <p>{t('home.advantages_text_2')}</p>
                      <p>{t('home.advantages_text_3')}</p>
                      <p>{t('home.advantages_text_4')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TrustpilotReviews />
        </div>
      </FadeIn>
    </>
  );
}

export default Home;

export async function getServerSideProps({ query }) {
  try {
    const menuItemsRes = await getMenuItems();
    const { data: menuItems = [] } = menuItemsRes;

    // TODO - remove static ids
    const quotationListForCarousel = await getQuotationListForCarouselQuotation([
      '6616636b5e59060eb60b7f3f',
      '661664075e59060eb60b7f4c',
      '661664895e59060eb60b7f57',
    ]);

    return {
      props: {
        menuItems,
        quotationListForCarousel,
      },
    };
  } catch (e) {
    return {
      props: {
        menuItems: [],
      },
    };
  }
}
