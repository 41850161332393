import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { track } from '../../utilities/trackerStatistics';

export default function HomeMutualInput(props) {
  const { t } = useTranslation();

  const router = useRouter();

  return (
    <div className="module-home-mutual-input">
      <div className="home-mutual-input-container">
        <button
          type="button"
          className="home-mutual-input-button"
          onClick={async () => {

            window.dataLayer.push({
              event: 'init_flusso',
              eventProps: { from: 'cta_header' },
            });

            track('vai al flusso', {
              from: 'cta_header',
            });

            router.push(`/cerca-mutuo`);
          }}>
          {t('home.main_banner_input_button')}
        </button>
      </div>
      <div className="home-mutual-description">{t('home.main_banner_input_text')}</div>
    </div>
  );
}